<template>
    <div v-if="!hasGolfClubData" class="d-flex justify-content-center align-items-center home-card-container">
        <Spinner />
    </div>
    <div v-else class="home-card-main h-100">
        <div class="card-grid h-100">
            <HomeCard v-for="(card, index) in allCards" :key="`card-${index}`" class="home-card" :image="card.image"
                :title="card.title" :index="index" :length="allCards.length" :card="card" />
        </div>
    </div>
</template>


<script>
import HomeCard from "../components/HomeCard.vue";
import Spinner from "../components/Spinner.vue";
import Header from "@/components/Header.vue";
import trophy from "@/assets/imgs/trophy.png";

export default {
    name: "HomeCards",

    components: {
        HomeCard,
        Header,
        Spinner,
    },

    data() {
        return {
            cards: [],
        };
    },

    computed: {
        hasGolfClubData() {
            return Object.keys(this.golfClubData || {}).length > 0;
        },

        topRowCards() {
            if (!this.golfClubData) return [];

            const cards = [];
            const {
                left_hand_honours_box_text,
                right_hand_honours_box_text,
                split_screen_third_party_logo,
                split_screen_board_logo
            } = this.golfClubData;

            // Left card (men's section)
            if (left_hand_honours_box_text || split_screen_board_logo) {
                cards.push({
                    type: 'mens',
                    image: split_screen_board_logo ? `${this.baseUrl}${split_screen_board_logo}` : trophy,
                    title: left_hand_honours_box_text || "Club Honours Board",
                });
            }

            // Right card (ladies section)
            if (right_hand_honours_box_text || split_screen_third_party_logo) {
                cards.push({
                    type: 'ladies',
                    image: split_screen_third_party_logo ? `${this.baseUrl}${split_screen_third_party_logo}` : trophy,
                    title: right_hand_honours_box_text || "Club Honours Board",
                });
            }

            return cards.length > 0 ? cards : this.getDefaultCards(1);
        },

        bottomRowCards() {
            if (!this.golfClubData) return [];

            const cards = [];
            const {
                bottom_left_honours_box_text,
                bottom_right_honours_box_text,
                bottom_left_logo,
                bottom_right_logo
            } = this.golfClubData;

            // Bottom left card (juniors section)
            if (bottom_left_honours_box_text || bottom_left_logo) {
                cards.push({
                    type: 'juniors',
                    image: bottom_left_logo ? `${this.baseUrl}${bottom_left_logo}` : trophy,
                    title: bottom_left_honours_box_text || "Juniors Honours Board",
                });
            }

            // Bottom right card (mixed section)
            if (bottom_right_honours_box_text || bottom_right_logo) {
                cards.push({
                    type: 'mixed',
                    image: bottom_right_logo ? `${this.baseUrl}${bottom_right_logo}` : trophy,
                    title: bottom_right_honours_box_text || "Mixed Honours Board",
                });
            }

            return cards;
        },

        hasBottomRowCards() {
            return this.bottomRowCards.length > 0;
        },

        allCards() {
            return [...this.topRowCards, ...this.bottomRowCards];
        }
    },

    methods: {
        getDefaultCards(count = 1) {
            return Array(count).fill().map(() => ({
                image: trophy,
                title: "Club Honours Board",
            }));
        },

        updateCards() {
            this.cards = this.allCards;
        }
    },

    watch: {
        golfClubData: {
            handler() {
                this.updateCards();
            },
            immediate: true
        }
    },

    mounted() {
        this.updateCards();
    }
};
</script>

<style scoped lang="scss">
.home-card-container {
    width: 100%;
    min-height: 300px;
}

.home-card-main {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.card-grid {
    align-items: center;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1.5rem;
    width: 100%;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
}


.home-card {
    min-height: 0;
    max-height: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
</style>