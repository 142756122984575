<template>
    <BCard class="homeCard cursor-pointer d-flex flex-column h-100" @click="navigateToAwardList">
        <BCardBody
            class="text-center d-flex flex-column justify-content-center gap-3 gap-md-5 align-items-center flex-grow-1">
            <img :src="image" class="homeCard__img" alt="" />
            <div class="w-100 d-flex flex-column align-items-center">
                <BCardTitle class="text-uppercase font-weight-bold homeCard__title mb-1 mb-md-4">
                    {{ title }}
                </BCardTitle>
                <BButton class="homeCard__btn border border-0 mb-0" @click.stop="navigateToAwardList">
                    Enter
                </BButton>
            </div>
        </BCardBody>
    </BCard>
</template>

<script>
import "../assets/scss/index.scss";

export default {
    props: {
        index: Number,
        title: String,
        description: String,
        image: String,
        length: Number,
        card: Object,
    },
    data() {
        return {
            awardCategory: "",
        };
    },
    methods: {
        navigateToAwardList() {
            this.$router.push({
                path: `/${this.$route.params.board}/awards-list/${this.card.type || ''}`,
            });
        },
    },
};
</script>

<style lang="scss">
.sdd {
    height: 70%;
}

.card-body {
    height: 100%;
}
</style>
